var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CSidebar',{attrs:{"colorScheme":"light","fixed":"","minimize":_vm.minimize,"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}}},[_c('div',{staticStyle:{"display":"flex"}},[_c('img',{staticClass:"navbar-brand",attrs:{"src":require('../assets/sosdenuncia/logo-region.png'),"alt":"región conectada"}}),_c('img',{staticClass:"navbar-brand",attrs:{"src":require('../assets/sosdenuncia/logo-arica.png'),"alt":"arica"}}),_c('button',{staticClass:"close-btn",on:{"click":function($event){_vm.show = false}}},[_c('img',{staticClass:"close-icon",attrs:{"src":require("../assets/imgicons/Close.svg")}})])]),_c('CSidebarNav',[_c('CSidebarNavItem',{attrs:{"name":"Mapa","to":"/dashboard","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 11@2x.png')
        }}}),_c('CSidebarNavItem',{attrs:{"name":"Denuncias","to":"/complaints","icon":{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 12@2x.png')
          }}}),_c('CSidebarNavItem',{attrs:{"name":"Estadísticas","to":"/statistics","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 11@2x.png')
        }}}),(_vm.checkPermissions(['view_cuadrantes']))?_c('CSidebarNavItem',{attrs:{"name":"Cuadrantes","to":"/cuadrantes","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 30@2x.png')
        }}}):_vm._e(),_c('CSidebarNavItem',{attrs:{"name":"Usuarios","to":"/users","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 15@2x.png')
        }}}),(_vm.checkPermissions(['view_publicidad']))?_c('CSidebarNavItem',{attrs:{"name":"Publicidad","to":"/publicidad","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 16@2x.png')
        }}}):_vm._e(),_c('CSidebarNavDivider',{staticClass:"my-2"}),_c('CSidebarNavItem',{attrs:{"name":"Configuración","to":"/configuration","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 16@2x.png')
        }}}),(_vm.role == 4)?_c('CSidebarNavItem',{attrs:{"name":"Mensajes","to":"/conversations","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 27.png')
        }}}):_vm._e(),_c('div',{on:{"click":function($event){return _vm.logOut()}}},[_c('CSidebarNavItem',{attrs:{"name":"Salir","to":"/login","icon":{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 29@2x.png')
        }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }