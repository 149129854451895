<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink class="tada" :class="alertas.length > 0 ? 'animated infinite':null">
        <CIcon size="xl" name="cil-bell"/>
        <CBadge color="primary" class="ml-auto">{{alertas.length}}</CBadge>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center text-white" color="primary">
      <strong>Alertas</strong>
    </CDropdownHeader>
    <CDropdownItem v-for="(alerta,index) in alertas" :key="index" @click="openAlert(alerta)">
      <div class="d-flex align-items-center">
        <CBadge color="primary" class="ml-auto mr-2">{{index + 1}}</CBadge>
        <div>
          {{alerta.nombre}} <small class="ml-5"><b>{{alerta.data.created_at}}</b></small>
        </div>
      </div>
    </CDropdownItem>
    <CDropdownItem v-if="alertas.length === 0">
      <div class="d-flex align-items-center">
        No se han generado alertas
      </div>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'
import {getAlert, alertasActivas} from '../api/alerts'

export default {
  name: 'AlertasDropdown',
  data () {
    return {
      alertas: [],
      animated: false
    }
  },
  async mounted(){
    const user = await localStorage.getItem('userData')
    this.admin = JSON.parse(user)
    this.cuadrante = this.admin.info.cuadrante_id
    this.childAddedListenner()
  },
  methods: {
    openAlert(alerta){
      console.log(alerta)
      this.$events.fire('showAlert', alerta)
    },
    childAddedListenner(){
      const self = this
      firebase.database(this.$store.state.primaryDB).ref().child('/SOSclick/Alertas/' + this.cuadrante).on("child_added", async function(snapshot, prevChildKey) {
        var alert = snapshot.val();
        const alertData = await getAlert({id: alert.alert_id})
        alert.data = alertData.data
        self.alertas.push(alert)
      })
      firebase.database(this.$store.state.primaryDB).ref('/SOSclick/Alertas/' + this.cuadrante).on("child_removed", function(data) {
        console.log(data)
        self.alertas = self.alertas.filter(function(item){
          console.log("ITEM: ", item)
          return item.id_usuario != data.key
        })
      })
      firebase.database(this.$store.state.primaryDB).ref().child('/SOSclick/ButtonAlertas/' + this.cuadrante).on("child_added", async function(snapshot, prevChildKey) {
        var alert = snapshot.val();
        const alertData = await getAlert({id: alert.alert_id})
        alert.data = alertData.data
        self.alertas.push(alert)
      })
      firebase.database(this.$store.state.primaryDB).ref('/SOSclick/ButtonAlertas/' + this.cuadrante).on("child_removed", function(data) {
        console.log(data)
        self.alertas = self.alertas.filter(function(item){
          console.log("ITEM: ", item)
          return item.id_usuario != data.key
        })
      })
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>

<style type="text/css">
@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.animated.infinite {
  animation-iteration-count: infinite;
}
.tada {
  animation-name: tada;
}
</style>