<template>
  <CSidebar 
    colorScheme="light"
    fixed 
    :minimize="minimize"
    :show.sync="show"
  >
      <div style="display: flex">
      <img
      :src="require('../assets/sosdenuncia/logo-region.png')"
      class="navbar-brand"
      alt="región conectada"
    />
    <img
      :src="require('../assets/sosdenuncia/logo-arica.png')"
      class="navbar-brand"
      alt="arica"
    />
        
      <button @click="show = false" class="close-btn">
        <img src='../assets/imgicons/Close.svg' class="close-icon">
      </button>
      </div>
    <CSidebarNav>
      <CSidebarNavItem
        name='Mapa'
        to='/dashboard'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 11@2x.png')
        }"/>
      <CSidebarNavItem
        name='Denuncias'
        to='/complaints'
        :icon="{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 12@2x.png')
          }"/>
      <CSidebarNavItem
        name='Estadísticas'
        to='/statistics'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 11@2x.png')
        }"/>
      <CSidebarNavItem
        v-if="checkPermissions(['view_cuadrantes'])"
        name='Cuadrantes'
        to='/cuadrantes'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 30@2x.png')
        }"/>
<!--       <CSidebarNavDropdown
        name="Usuarios Panel"
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 18@2x.png')
        }">
        <CSidebarNavItem
          v-if="checkPermissions(['isSuperAdmin', 'view_user_admin'])"
          name='Usuarios SuperAdmin'
          to='/superadmin-users'
          :icon="{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 18@2x.png')
          }"/>
        <CSidebarNavItem
          name='Usuarios Panel'
          to='/admin-users'
          :icon="{
            class: 'menu-icons-img',
            src: require('../assets/imgicons/ICONOS SOSRecurso 18@2x.png')
          }"/>
      </CSidebarNavDropdown> -->
      <CSidebarNavItem
      
        name='Usuarios'
        to='/users'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 15@2x.png')
        }"/>
      <CSidebarNavItem
        v-if="checkPermissions(['view_publicidad'])"
        name='Publicidad'
        to='/publicidad'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 16@2x.png')
        }"/>
      <CSidebarNavDivider class="my-2"/>
      <CSidebarNavItem
        name='Configuración'
        to='/configuration'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 16@2x.png')
        }"/>
      <CSidebarNavItem
        v-if="role == 4"
        name='Mensajes'
        to='/conversations'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 27.png')
        }"/>
        
        
      <div @click="logOut()">
      <CSidebarNavItem
        name='Salir'
        to='/login'
        :icon="{
          class: 'menu-icons-img',
          src: require('../assets/imgicons/ICONOS SOSRecurso 29@2x.png')
        }"/>
      </div>
        
    </CSidebarNav>
<!--     <CSidebarBrand 
      :imgFull="{ width: 118, alt: 'Logo', src: require('../assets/imgs/logo-iduam.png')}"
      :imgMinimized="{ width: 60, alt: 'Logo', src: require('../assets/imgs/logo-iduam.png')}"
      :wrappedInLink="{ href: '/', target: '_blank'}"
    /> -->
    <!-- <CRenderFunction flat :content-to-render="nav"/> -->
<!--     <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="minimize = !minimize"
    /> -->
  </CSidebar>
</template>

<script>
import nav from './_nav'
import checkPermissions from '../helpers/userPermissions'

export default {
  name: 'TheSidebar',
  data () {
    return {
      minimize: false,
      nav,
      show: false,
      role: null
    }
  },
  watch:{
    $route (to, from){
      this.show = false
    }
  },
  mounted () {
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive'
      this.show = sidebarOpened ? false : 'responsive'
    })
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false
      this.show = sidebarClosed ? true : 'responsive'
    })

    this.role = JSON.parse(localStorage.getItem('userData'))?.role?.user_role_id
  },
  methods:{
    checkPermissions,
    async logOut(){
      console.log("Salir?")
      await localStorage.removeItem('token')
      this.$router.push('Login')
    },
  }
}
</script>
<style scoped>
  .close-btn {
    border: none;
    display: flex;
    margin-left: 20px;
  }
.close-btn:focus {outline:0;}
  .close-icon {
    margin-left: auto;
    width: 20%;  
    margin-top: 10px;
    width: 100%;
  }
  .navbar-brand {
    margin-top: 5px;
    margin-left: 10px;
    width: 27%;
  }
</style>
