<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink style="color: #000">
        Opciones <font-awesome-icon icon="chevron-down" style="margin-left: 5px" />
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center text-white" color="primary">
      <strong>Opciones</strong>
    </CDropdownHeader>
    <CDropdownItem @click="toggleModalAudio">
      <font-awesome-icon icon="microphone" style="margin-right: 10px" /> Ajustes de Audio
    </CDropdownItem>
    <CDropdownItem @click="toggleSearchUserModal">
      <font-awesome-icon icon="user" style="margin-right: 10px" /> Buscar Usuarios
    </CDropdownItem>
    <CDropdownItem @click="toggleDeleteAllComplaintsModal">
      <font-awesome-icon icon="trash" style="margin-right: 10px" /> Borrar denuncias
      activas
    </CDropdownItem>
    <div class="d-flex align-items-center item-drop-custom">
      <CSwitch
        class="mr-2"
        size="sm"
        color="primary"
        shape="pill"
        @update:checked="toggleAlarmSound"
        :checked="$store.state.alarmSound"
      />
      <div>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px">
          <strong>Sonido Alertas</strong>
        </p>
      </div>
    </div>
    <div class="d-flex align-items-center item-drop-custom">
      <CSwitch
        class="mr-2"
        size="sm"
        color="primary"
        shape="pill"
        :checked.sync="$store.state.showVideo"
      />
      <div>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px">
          <strong>{{
            $store.state.showVideo ? "Atención Primaria" : "Seguridad"
          }}</strong>
        </p>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px">
          {{
            $store.state.showVideo
              ? "Videoconferencia Automática"
              : "Monitoreo Silencioso"
          }}
        </p>
      </div>
    </div>
    <div class="d-flex align-items-center item-drop-custom">
      <CSwitch
        class="mr-2"
        size="sm"
        color="primary"
        shape="pill"
        @update:checked="toggleAutoVideoOnAlert"
        :checked.sync="$store.state.autoVideoOnAlert"
      />
      <div>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px">
          <strong>Videollamada automática en Alerta</strong>
        </p>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px">
          {{ $store.state.autoVideoOnAlert ? "Activado" : "Desactivado" }}
        </p>
      </div>
    </div>
    <div class="d-flex align-items-center item-drop-custom">
      <CSwitch
        class="mr-2"
        size="sm"
        color="primary"
        shape="pill"
        @update:checked="toggleAutoVideoRecordOnAlert"
        :checked.sync="$store.state.autoVideoRecordOnAlert"
      />
      <div>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px">
          <strong>Grabación automática en Alerta</strong>
        </p>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px">
          {{ $store.state.autoVideoRecordOnAlert ? "Activado" : "Desactivado" }}
        </p>
      </div>
    </div>
    <!--     <div class="d-flex align-items-center item-drop-custom">
      <CSwitch
        class="mr-2"
        size="sm"
        color="primary"
        shape="pill"
        @update:checked="toggleGroupMarkers"
        :checked.sync="$store.state.groupMarkers"
      />
      <div>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px">
          <strong>Agrupar marcadores</strong>
        </p>
        <p class="mb-0 mr-2 text-muted" style="font-size: 10px">
          {{ $store.state.groupMarkers ? "Activado" : "Desactivado" }}
        </p>
        <input
          v-show="$store.state.groupMarkers"
          type="range"
          min="1"
          max="100"
          v-model="groupMarkers"
        />
      </div>
    </div> -->
  </CDropdown>
</template>

<script>
import * as firebase from "firebase/app";
import "firebase/database";

export default {
  name: "OptionsDropdown",
  data() {
    return {
      alertas: [],
      animated: false,
      groupMarkers: 0,
    };
  },
  mounted() {},
  methods: {
    toggleAlarmSound() {
      this.$store.commit("setAlarmSound", !this.$store.state.alarmSound);
    },
    toggleSearchUserModal() {
      this.$store.commit(
        "setShowUserModalSearch",
        !this.$store.state.showUserModalSearch
      );
    },
    toggleAutoVideoOnAlert() {
      this.$store.commit("setAutoVideoOnAlert", !this.$store.state.autoVideoOnAlert);
    },
    toggleAutoVideoRecordOnAlert() {
      this.$store.commit(
        "setAutoVideoRecordOnAlert",
        !this.$store.state.autoVideoRecordOnAlert
      );
    },
    toggleModalAudio() {
      this.$store.commit("setModalAudioSettings", !this.$store.state.modalAudioSettings);
    },
    toggleDeleteAllComplaintsModal() {
      this.$store.commit(
        "setModalDeleteAllComplaints",
        !this.$store.state.modalDeleteAllComplaints
      );
    },
    toggleModalAudio() {
      this.$store.commit("setGroupMarkers", !this.$store.state.groupMarkers);
    },
    toggleGroupMarkers() {
      if (this.$store.state.groupMarkers) {
        this.$store.commit("setGridSize", 0);
        this.groupMarkers = 0;
      }
      this.$store.commit("setGroupMarkers", !this.$store.state.groupMarkers);
    },
  },
  watch: {
    groupMarkers(value) {
      if (!this.$store.state.groupMarkers) {
        this.$store.commit("setGridSize", 0);
      }
      this.$store.commit("setGridSize", +value);
    },
  },
};
</script>
